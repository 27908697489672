import './index.css'

import React from 'react'
import { hydrate, render } from 'react-dom'

import App from './App'
import { AlertProvider } from './context/AlertContext'
import { BrowserRouter as Router } from 'react-router-dom';
import { GameProvider } from './context/Custom'

const rootElement = document.getElementById('root');
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Router>
        <GameProvider>
          <AlertProvider>
            <App />
          </AlertProvider>
        </GameProvider>
      </Router>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <Router>
        <GameProvider>
          <AlertProvider>
            <App />
          </AlertProvider>
        </GameProvider>
      </Router>
    </React.StrictMode>,
    rootElement
  );
}
