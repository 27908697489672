import { Game } from "@/interfaces/game";
import { GAMES } from "./../games/games";
import { firstGameDate } from "./../lib/words";
import { addDays, format } from 'date-fns';
import { useState } from 'react';

const periodInDays = 1; // Update this with the actual period between games

const Archive = () => {
    const [password, setPassword] = useState('');
    const [isPasswordCorrect, setPasswordCorrect] = useState(localStorage.getItem('passwordCorrect') === 'true');
    const games = GAMES;

    const handleGameClick = (game: Game) => {
        const gameData = {
            prompt: game.text,
            answer: game.answer,
            lucky: game.luckyGuess,
        };

        const encodedData = btoa(JSON.stringify(gameData));

        window.location.assign(`${window.location.origin}/?game=${encodedData}`);
    };

    const checkPassword = () => {
        if (password === 'solver') {
            setPasswordCorrect(true);
            localStorage.setItem('passwordCorrect', 'true');
        }
    }

    return (
        <>
            <div className="flex items-center justify-center text-center text-black dark:text-white">
                <div>
                    <h2 className="font-bold text-xl text-center mb-2 mt-4">Game Archive</h2>
                    {isPasswordCorrect ? (
                        <>
                            <p className="mt-2 mb-2 text-lg">Hi Tom 👋</p>
                            {games.map((game, index) => {
                                const gameDate = addDays(firstGameDate, index * periodInDays);
                                const formattedDate = format(gameDate, 'dd/MM/yyyy');
                                return (
                                    <p key={index} style={{ cursor: "pointer" }} onClick={() => handleGameClick(game)} className="hover:underline">
                                        {formattedDate}: <span style={{ cursor: "pointer" }} className="">{game.text}</span>
                                    </p>
                                );
                            })}
                            <br />
                            <br />
                            <br />
                        </>
                    ) : (
                        <>
                            <div className="flex flex-col">
                                <input
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    placeholder="Enter password"
                                    className="rounded mt-2 bg-white dark:bg-slate-700 border-2 border-gray-300 dark:border-neutral-700 p-2"
                                />
                                <button onClick={checkPassword} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-2 rounded">Submit</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Archive;
