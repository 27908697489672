import { Link } from 'react-router-dom';

export const Footer = () => (
    <div className="text-center">
        <footer className="footer mb-2 flex gap-3 items-center justify-center w-full">
            <Link to={"/privacy"} className="text-blue-600 dark:text-blue-300">
                Privacy Policy
            </Link>
            <Link to={"/feedback"} className="text-blue-600 dark:text-blue-300">
                Feedback
            </Link>
        </footer>
        <div className='h-48'></div>
    </div>
);