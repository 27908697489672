import './App.css'

import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, Route, Routes } from 'react-router-dom'

import { AlertContainer } from './components/alerts/AlertContainer'
import Confetti from './components/confetti/Confetti'
import { Footer } from './components/footer/Footer'
import { Grid } from './components/grid/Grid'
import { Input } from './components/input/Input'
import { Keyboard } from './components/keyboard/Keyboard'
import { DatePickerModal } from './components/modals/DatePickerModal'
import { InfoModal } from './components/modals/InfoModal'
import { MigrateStatsModal } from './components/modals/MigrateStatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { StatsModal } from './components/modals/StatsModal'
import { Navbar } from './components/navbar/Navbar'
import {
  DISCOURAGE_INAPP_BROWSERS,
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS, // WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  CORRECT_WORD_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  GAME_COPIED_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  SHARE_FAILURE_TEXT,
  WORD_NOT_FOUND_MESSAGE,
} from './constants/strings'
import { useAlert } from './context/AlertContext'
import { useGameContext } from './context/Custom'
import { isInAppBrowser } from './lib/browser'
import {
  getStoredIsHighContrastMode,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
} from './lib/localStorage'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import { validWords } from './lib/validWords'
import {
  findFirstUnusedReveal,
  getIsLatestGame,
  getJustSolution,
  getLuckyOfTheDay,
  isWinningWord,
  setGameDate,
  solutionGameDate,
  unicodeLength,
} from './lib/words'
import About from './pages/About'
import Archive from './pages/Archive'
import ArchiveUser from './pages/ArchiveUser'
import CreateYourOwn from './pages/CreateYourOwn'
import Feedback from './pages/Feedback'
import Privacy from './pages/Privacy'

// import NotFoundPage from './pages/NotFound';

function App() {
  const isLatestGame = getIsLatestGame()
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [shouldCheckGuess, setShouldCheckGuess] = useState(false)
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)
  const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [solution, setSolution] = useState(getJustSolution(false, ''))
  const [, setCreateOwn] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage(isLatestGame)
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      // showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
      //   persist: false,
      // })
    }
    return loaded.guesses
  })
  const { isGameFromLink, checkQueryParams, answer, lucky } = useGameContext()
  const [stats, setStats] = useState(() => loadStats())

  const loadAdScript = () => {
    const href = window.location.href
    let src = ''
    if (href.includes('staging') || href.includes('auth')) {
      console.log('Loading STAGING ads script')
      src = 'https://staging-cdn.snigelweb.com/adengine/searchle.net/loader.js'
    } else if (href.includes('searchle.net')) {
      console.log('Loading LIVE ads script')
      src = 'https://cdn.snigelweb.com/adengine/searchle.net/loader.js'
    }

    const node = document.createElement('script')
    node.src = src
    node.type = 'text/javascript'
    node.async = true
    node.setAttribute('data-cfasync', 'false')
    document.getElementsByTagName('head')[0].appendChild(node)
  }

  useEffect(() => {
    checkQueryParams()
    loadAdScript()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const luckyGuess = () => {
    if (Math.random() < 0.01) {
      if (!isGameFromLink) {
        setCurrentGuess(solution.toUpperCase())
      } else {
        setCurrentGuess(answer.toUpperCase())
      }
    } else {
      if (!isGameFromLink) {
        const luckys = getLuckyOfTheDay()
        setCurrentGuess(luckys.toUpperCase())
      } else {
        setCurrentGuess(lucky.toUpperCase())
      }
    }
    setShouldCheckGuess(true)

    const today = new Date().toISOString().split('T')[0]
    localStorage.setItem('luckyGuessDate', today)
  }

  useEffect(() => {
    if (isGameFromLink) {
      setSolution(answer.toUpperCase())
      setGuesses([])
      setIsGameWon(false)
      setIsGameLost(false)
    }
  }, [answer, isGameFromLink])

  function populateValidWord(): boolean {
    const validWord = localStorage.getItem('validWord')
    if (validWord === 'false') {
      return false
    } else {
      return true
    }
  }

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )
  const [isValidWordRule, setIsValidWordRule] = useState(populateValidWord())

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 3000,
      })
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }
  const handleValidRuleMode = (isValid: boolean) => {
    setIsValidWordRule(isValid)
    localStorage.setItem('validWord', isValid ? 'true' : 'false')
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    if (!isGameFromLink) {
      saveGameStateToLocalStorage(getIsLatestGame(), { guesses, solution })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guesses])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (
      isValidWordRule &&
      !validWords.includes(currentGuess.toLowerCase()) &&
      currentGuess !== solution
    ) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!(unicodeLength(currentGuess) === solution.length)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * solution.length)

    const winningWord = isWinningWord(
      currentGuess,
      isGameFromLink,
      answer.toUpperCase()
    )

    if (
      unicodeLength(currentGuess) === solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        if (isLatestGame && !isGameFromLink) {
          setStats(addStatsForCompletedGame(stats, guesses.length))
        }
        if (!isGameFromLink) {
          setTimeout(() => {
            setIsStatsModalOpen(true)
          }, (solution.length + 1) * REVEAL_TIME_MS + 2000)
        }

        setTimeout(() => {
          setIsVisible(true)
          setCreateOwn(true)
        }, REVEAL_TIME_MS * solution.length + 50)
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        if (isLatestGame && !isGameFromLink) {
          setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        }
        if (!isGameFromLink) {
          setTimeout(() => {
            setIsStatsModalOpen(true)
          }, (solution.length + 1) * REVEAL_TIME_MS + 2000)
        }
        setIsGameLost(true)
        setCreateOwn(true)
        showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
          persist: false,
          delayMs: REVEAL_TIME_MS * solution.length + 1,
        })
      }
    }
  }

  useEffect(() => {
    if (currentGuess !== '' && shouldCheckGuess) {
      onEnter()
      setShouldCheckGuess(false) // reset after checking
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGuess, shouldCheckGuess])

  return (
    <div className="relative">
      <Helmet>
        <title>Searchle | The Daily Google Autocomplete Game</title>
      </Helmet>
      <div className="view-full">
        <Navbar
          setIsInfoModalOpen={setIsInfoModalOpen}
          setIsStatsModalOpen={setIsStatsModalOpen}
          setIsDatePickerModalOpen={setIsDatePickerModalOpen}
          setIsSettingsModalOpen={setIsSettingsModalOpen}
        />
        <div className="absolute left-0 top-20 custom-breakpoint:left-20">
          <div id="adngin-sidebar_left-0" />
        </div>
        {/* <div
          className="snigel-sidev  absolute left-0 top-0 h-full -translate-x-full items-end justify-start"
          style={{
            zIndex: 200,
          }}
        >
          <div
            className="sticky top-0 inline-flex "
            style={{
              zIndex: 10000,
              height: 'auto',
              padding: '20px 40px 20px 20px',
              pointerEvents: 'all',
            }}
          >

            <div className="flex flex-col items-center justify-center">
              <div id="adngin-sidebar_left-0" />
            </div>

          </div>
        </div> */}
        <div className="flex min-h-[52px] items-center justify-center md:min-h-[0px]">
          <div id="adngin-top_banner-0"></div>
        </div>
        <Routes>
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/upcoming" element={<Archive />} />
          <Route path="/archive" element={<ArchiveUser />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/create" element={<CreateYourOwn />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/"
            element={
              <div className="flex h-full flex-col">
                {isVisible && <Confetti />}

                {/* 
          {!isLatestGame && (
            <div className="flex items-center justify-center">
              <ClockIcon className="h-6 w-6 stroke-gray-600 dark:stroke-gray-300" />
              <p className="text-base text-gray-600 dark:text-gray-300">
                {format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE })}
              </p>
            </div>
          )} */}

                <div className="mx-auto flex w-full flex-col px-1 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
                  <div className="mb-2 md:my-4">
                    <Input currentGuess={currentGuess} />
                  </div>
                  <div className="flex grow flex-col justify-start pb-1">
                    <Grid
                      solution={solution}
                      guesses={guesses}
                      currentGuess={currentGuess}
                      isRevealing={isRevealing}
                      currentRowClassName={currentRowClass}
                    />
                  </div>
                  <Keyboard
                    onChar={onChar}
                    onDelete={onDelete}
                    onEnter={onEnter}
                    solution={solution}
                    guesses={guesses}
                    isRevealing={isRevealing}
                  />
                  {(!isGameFromLink ||
                    (isGameFromLink && lucky && lucky !== '')) &&
                    guesses.length === 0 && (
                      <div className="mt-1 flex items-center justify-center">
                        <span
                          className="cursor-pointer text-center text-blue-600 dark:text-blue-300"
                          aria-label="button"
                          onClick={luckyGuess}
                        >
                          I'm feeling lucky
                        </span>
                      </div>
                    )}
                  {isGameLost || isGameWon ? (
                    <>
                      <div className="flex items-center justify-center">
                        <Link
                          to={'/create'}
                          className="mt-1 cursor-pointer text-center text-blue-600 dark:text-blue-300"
                        >
                          Create your own
                        </Link>
                        <span className="translate-y-px">
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                        </span>
                        <Link
                          to={'/archive'}
                          className="mt-1 cursor-pointer text-center text-blue-600 dark:text-blue-300"
                        >
                          Play the archive
                        </Link>
                      </div>
                      {isGameLost && (
                        <div className="text-center dark:text-white">
                          <span>The word was {solution}</span>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            }
          />
          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
        <div className="flex items-center justify-center text-black dark:text-white">
          <div className="mb-4 flex w-72 flex-col items-center justify-center rounded-2xl border border-black p-3 px-8 shadow-lg dark:border-white">
            <p className="mb-1">Four BRAND NEW word games</p>
            <a href="https://clevergoat.com" target="_blank" rel="noreferrer">
              <img src="/logo.png" alt="Clever Goat" className="dark:invert" />
            </a>
            <p>
              Play at{' '}
              <a
                href="https://clevergoat.com"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 dark:text-blue-300"
              >
                clevergoat.com
              </a>
            </p>
          </div>
        </div>

        <InfoModal
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
        <StatsModal
          isOpen={isStatsModalOpen}
          handleClose={() => setIsStatsModalOpen(false)}
          solution={solution}
          guesses={guesses}
          gameStats={stats}
          isLatestGame={isLatestGame}
          isGameLost={isGameLost}
          isGameWon={isGameWon}
          handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
          handleShareFailure={() =>
            showErrorAlert(SHARE_FAILURE_TEXT, {
              durationMs: LONG_ALERT_TIME_MS,
            })
          }
          handleMigrateStatsButton={() => {
            setIsStatsModalOpen(false)
          }}
          isHardMode={isHardMode}
          isDarkMode={isDarkMode}
          isHighContrastMode={isHighContrastMode}
          numberOfGuessesMade={guesses.length}
        />
        <DatePickerModal
          isOpen={isDatePickerModalOpen}
          initialDate={solutionGameDate}
          handleSelectDate={(d) => {
            setIsDatePickerModalOpen(false)
            setGameDate(d)
          }}
          handleClose={() => setIsDatePickerModalOpen(false)}
        />
        <MigrateStatsModal
          isOpen={isMigrateStatsModalOpen}
          handleClose={() => setIsMigrateStatsModalOpen(false)}
        />
        <SettingsModal
          isOpen={isSettingsModalOpen}
          handleClose={() => setIsSettingsModalOpen(false)}
          isHardMode={isHardMode}
          handleHardMode={handleHardMode}
          isDarkMode={isDarkMode}
          handleDarkMode={handleDarkMode}
          isHighContrastMode={isHighContrastMode}
          handleHighContrastMode={handleHighContrastMode}
          isValidWordRule={isValidWordRule}
          handleValidWordRule={handleValidRuleMode}
        />
        <AlertContainer />
      </div>
      <Footer />
    </div>
  )
}

export default App
