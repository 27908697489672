export default function Feedback() {
    return (
        <>
            <div className='flex items-center justify-center w-full px-6 mb-10 md:px-0'>
                <div className='w-full text-black dark:text-white mt-4 max-w-md'>
                    <h2 className='text-2xl font-bold'>Feedback</h2>
                    <div className='mt-4 flex flex-col gap-3'>
                        <p>Do you have feedback for Searchle? We're a small team that works on this game and want to make it as enjoyable as possible.</p>
                        <p>If you'd like to help imporve the game, follow the link below which will take you to a google form.</p>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSd6mc06zOy5wXLhHUCAY-0lUICDJ9s2rKLVvS-2GpVKNZSiCA/viewform" target="_blank" rel="noreferrer">
                            <button className='bg-green-500 text-white p-2 rounded-md mt-3'>Submit feedback</button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}