
import {
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
  HomeIcon,
  ClockIcon,
  PlusCircleIcon

} from '@heroicons/react/outline'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsDatePickerModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}


export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
}: Props) => {
  const navigate = useNavigate();

  const handleHome = () => {
    const currentURL = window.location.href;
    if (currentURL.includes("game")) {
      window.location.href = "/";
      return;
    } else {
      navigate("/");
    }
  }

  return (
    <div className="navbar md:mb-0 py-1">
      <div className="navbar-content px-5 short:h-auto flex justify-between">
        <div className="flex">
          <HomeIcon
            className="h-5 w-5 md:h-6 md:w-6 cursor-pointer dark:stroke-white" onClick={handleHome}
          />

          <Link to={"/about"}>
            <InformationCircleIcon
              className="ml-3 h-5 w-5 md:h-6 md:w-6 cursor-pointer dark:stroke-white"
            />
          </Link>
          <Link to={"/create"}>
            <PlusCircleIcon
              className="ml-3 h-5 w-5 md:h-6 md:w-6 cursor-pointer dark:stroke-white"
            />
          </Link>

        </div>
        <div className="flex-grow flex justify-center">
          <div className={`text-center text-3xl md:text-4xl product-sans`}>
            <div className='flex items-end relative'>
              <h1 className="product-sans-bold font-bold flex">
                <span className="text-[#4285F4]">S</span>
                <span className="text-[#DB4437]">e</span>
                <span className="text-[#F4B400]">a</span>
                <span className="text-[#4285F4]">r</span>
                <span className="text-[#0F9D58]">c</span>
                <span className="text-[#DB4437]">h</span>
                <span className="text-[#4285F4]">l</span>
                <span className="text-[#0F9D58]">e</span>
              </h1>
              {/* <img src="/hat.png" alt='hat' className="w-6 h-6  md:h-7 md:w-7 ml-2 absolute right-[-10px] md:right-[-11.5px] top-[8px]" /> */}
            </div>
          </div>
        </div>
        <div className="right-icons flex">
          <Link to={'/archive'}>
            <ClockIcon
              className="mr-3 h-5 w-5 md:h-6 md:w-6 cursor-pointer dark:stroke-white"
            />
          </Link>
          <ChartBarIcon
            className="mr-3 h-5 w-5 md:h-6 md:w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <CogIcon
            className="h-5 w-5 md:h-6 md:w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
