import { useGameContext } from './../../context/Custom';
import { getGameDate, getIndex, getTextOfDay } from './../../lib/words';
import React, { useEffect, useState } from 'react';

type InputProps = {
    currentGuess: string;
};

export const Input = ({ currentGuess }: InputProps) => {
    const gDate = getGameDate();
    const index = getIndex(gDate);
    const [gameText, setGameT] = useState(getTextOfDay(index));
    const { isGameFromLink, prompt } = useGameContext();

    useEffect(() => {
        if (isGameFromLink) {
            setGameT(prompt);
        } else {
            setGameT(getTextOfDay(index));
        }
    }, [isGameFromLink, prompt, index]);

    return (
        <>
            <div className="flex flex-col items-center justify-center">
                <h2 className='text-black dark:text-white mb-1 text-center text-base md:text-lg'>How does google <span className='product-sans-bold'>autocomplete</span> this search?</h2>
                <div className='mx-3 md:mx-0'>
                    <div className="h-9 md:h-11  w-80 md:w-96 bg-white dark:bg-slate-600 dark:text-white border-2 border-neutral-200 rounded-full flex items-center px-3 md:text-lg">
                        <img src="/icon_search.svg" alt="search" className="w-5 dark:invert" />
                        <p className="ml-3 whitespace-nowrap">{isGameFromLink ? prompt : gameText}<span> {currentGuess.toLowerCase()}</span></p>
                    </div>
                </div>
            </div>
        </>
    );
}