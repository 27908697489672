
import { PlusCircleIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

type Props = {
  handleMigrateStatsButton: () => void
}

export const MigrationIntro = ({ handleMigrateStatsButton }: Props) => {
  return (
    <>
      <div className="mt-5 columns-2 items-center items-stretch justify-center text-center dark:text-white sm:mt-6">
        <div className="mt-3 text-xs">Create your own Searchle and share it with friends!</div>
        <Link to={"/create"}>
          <button
            type="button"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-[#0f9D58] px-4 py-2 text-center text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            onClick={handleMigrateStatsButton}
          >
            <PlusCircleIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
            Create
          </button>
        </Link>
      </div>
      <div className='mt-4'>
        <p className='text-sm dark:text-white'>
          Have an idea for a Searchle puzzle? <a href='https://forms.gle/zybBAJX7brCFP8aq8' target='_blank' rel='noreferrer' className='cursor-pointer text-blue-600 hover:text-blue-400'>Let us know!</a>
        </p>
      </div>
      {/* <div className="mt-5 columns-2 items-center items-stretch justify-center text-center dark:text-white sm:mt-6">
        <div className="mt-3 text-xs">Have an idea for a Searchle puzzle? Let us know!</div>
        <Link to={"/create"}>
          <button
            type="button"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-center text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            onClick={handleMigrateStatsButton}
          >
            <PlusCircleIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
            Suggest
          </button>
        </Link>
      </div> */}
    </>
  )
}
